




















































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import VIcon from "./VIcon.vue";
import ClickOutside from "../directives/ClickOutside";

export interface VSelectOption {
  id: string;
  text: string;
  editable?: boolean;
  new?: boolean;
  subtitle?: string;
}

@Component({ components: { VIcon }, directives: { ClickOutside } })
export default class VSelect extends Vue {
  @Prop() readonly value!: string | null;
  @Prop({ default: "Seleziona un'opzione" }) readonly placeholder?: string;
  @Prop() readonly options!: VSelectOption[];
  @Prop({ required: false }) readonly required!: boolean;
  @Prop({ default: false }) readonly addable!: boolean;
  @Prop({ default: false }) readonly editable!: boolean;
  @Prop({ default: false }) readonly expanded!: boolean;
  @Prop({ default: true }) readonly autoSort!: boolean;
  @Prop({ default: "top" }) readonly addableAlign!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: true }) readonly scrollable!: boolean;
  @Prop({ default: true }) readonly loading!: boolean;
  @Prop({ default: true }) readonly inputClass!: boolean;
  @Prop({ default: false }) readonly searchable!: boolean;
  private term = "";


  @Watch("value", { immediate: true })
  onValueChange() {
    this.updateSelected();
  }

  @Watch("term")
  onTermChanged(newVal: string) {
    this.$emit("searchChanged", newVal);
  }

  @Watch("options", { immediate: true })
  onOptionChange(value: VSelectOption[]) {
    this.selectableOptions = [...value];
    this.updateSelected();
  }

  dropdownVisible = false;
  editing: VSelectOption | null = null;

  selected: VSelectOption | null = null;
  selectableOptions: VSelectOption[] = [];

  adding = {
    enabled: false,
    text: ""
  };

  get showDropdown() {
    return this.dropdownVisible || this.expanded;
  }

  get label(): string {
    if (this.selected == null) {
			if(this.loading) {
				return '';
			}
      return this.placeholder || "";
    }

    return this.selected.text;
  }

  created() {
    this.updateSelected();
  }

  updateSelected() {
    if (this.value == null) {
      this.selected = null;
    } else {
      this.selected =
        this.selectableOptions.find(element => element.id == this.value) ||
        null;
    }
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  closeDropdown() {
    this.dropdownVisible = false;
  }

  select(option: VSelectOption) {
    this.selected = option;
    this.closeDropdown();
    this.$emit("input", this.selected.id);
  }

  add() {
    if (this.adding.text == "") {
      return;
    }

    const option: VSelectOption = {
      id: `new_${this.selectableOptions.length + 1}`,
      text: this.adding.text,
      new: true
    };

    if (this.editable) {
      option.editable = true;
    }

    this.selectableOptions.push(option);
    this.sortOptions();

    this.selected = option;

    this.stopAdding();
    this.closeDropdown();

    this.$emit("added", option);
  }

  startAdding() {
    this.adding.enabled = true;
    this.$nextTick(() => {
      (this.$refs.input as HTMLInputElement).focus();
    });
  }

  stopAdding() {
    this.adding.enabled = false;
    this.adding.text = "";
  }

  edit() {
    this.closeDropdown();

    this.$emit("edited", this.editing);
    this.stopEditing();
  }

  startEditing(option: VSelectOption) {
    this.editing = option;
    this.$nextTick(() => {
      (this.$refs.editInput as any)[0]?.focus();
    });
  }

  stopEditing() {
    this.editing = null;
  }

  sortOptions() {
    if (!this.autoSort) {
      return;
    }
    this.selectableOptions.sort((a: VSelectOption, b: VSelectOption) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      } else if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }

      return 0;
    });
  }

  isSelected(option: VSelectOption) {
    if (this.selected == null) {
      return false;
    }

    return this.selected.id == option.id;
  }
}
