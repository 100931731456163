import { Component, Vue } from 'vue-property-decorator';

import { BASE_API_URL } from '@/utils/Constants';

const BASE_SF_SECTIONS_GROUP_URL = BASE_API_URL + "store_front_sections";

export enum StoreFrontSectionsMixinTags {
  Index = 'StoreFrontSectionIndex',
  Show = 'StoreFrontSectionShow',
  Update = 'UpdateStoreFrontSection',
  Create = 'CreateStoreFrontSection',
  Destroy = 'DestroyStoreFrontSection'
}

@Component
export default class StoreFrontSectionsMixin extends Vue {
  getStoreFrontSections(data: any, tag: string) {
    return this.$httpGetQuery(BASE_SF_SECTIONS_GROUP_URL, tag, data);
  }

  getStoreFrontSection(id: number | string, tag: string) {
    return this.$httpGet(BASE_SF_SECTIONS_GROUP_URL + "/" + id, tag);
  }

  createStoreFrontSection(data: any, tag: string) {
    return this.$httpPost(BASE_SF_SECTIONS_GROUP_URL, tag, data);
  }

  updateStoreFrontSection(id: number | string, data: any, tag: string) {
    return this.$httpPut(BASE_SF_SECTIONS_GROUP_URL + "/" + id, tag, data);
  }

  destoryStoreFrontSection(id: number | string, tag: string) {
    return this.$httpDelete(BASE_SF_SECTIONS_GROUP_URL + "/" + id, tag);
  }
}